import { yupResolver } from "@hookform/resolvers/yup"
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAuth } from "src/hooks/use-auth";
import { useVerifyConfirmationCodeMutation } from "src/services/anonymousApi";
import { useResendConfirmationCodeMutation } from "src/services/authApi";
import { useUpdateAzureEmailMutation } from "src/services/userApi";
import { useCountdown } from "usehooks-ts";

import { AddMicrosoftAccountDialogProps } from "./add-microsoft-account-dialog.component";
import { addMicrosoftAccountDialogFormValidation } from "./add-microsoft-account-dialog.validation";

type View = 'email' | 'verification';

const countDownConfig = {
    countStart: 600,
    intervalMs: 1000,
};

interface FieldValues {
    emailAddress: string;
    verificationCode: string;
}

export const useAddMicrosoftAccountDialogState = (props: AddMicrosoftAccountDialogProps) => {
    const { user } = useAuth();
    const [activeView, setActiveView] = useState<View>('email');
    const [verificationCodeCountDown, { startCountdown, resetCountdown, stopCountdown }] = useCountdown(countDownConfig);
    const [resendConfirmationCode, { isLoading: isReSendingConfirmationCode }] = useResendConfirmationCodeMutation();
    const [verifyConfirmationCode, { isLoading: isVerifyConfirmationCodeLoading }] = useVerifyConfirmationCodeMutation();
    const [updateAzureEmail] = useUpdateAzureEmailMutation();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        setError,
        formState: {
            isSubmitting,
            errors
        } } = useForm<FieldValues>({
            resolver: yupResolver(addMicrosoftAccountDialogFormValidation),
            defaultValues: {
                emailAddress: user.alternativeAzureEmail ?? '',
                verificationCode: '',
            },
        });

    const onChangeEmail = () => {
        setActiveView('email');
    }

    const onSubmit = async (data: FieldValues) => {
        try {
            resetCountdown();
            await updateAzureEmail({ emailAddress: data.emailAddress }).unwrap();
            setActiveView('verification');
            toast.success('Verification code sent');
            startCountdown();
        } catch (error) {
            setError('emailAddress', {
                type: 'manual',
                message: "Email is not associated with Microsoft",
            });
        }

    };


    const onResendConfirmationCode = async () => {
        try {
            resetCountdown();
            setValue('verificationCode', '');
            const response = await resendConfirmationCode({
                confirmationCode: null,
                confirmationCode2: null,
                lenderUniqueId: null,
                password: null,
                provider: null,
                url: null,
                username: user.emailAddress,
            }).unwrap();
            if (response.success) {
                setError('verificationCode', {
                    type: 'manual',
                    message: '',
                });
                toast.success('Verification code sent');
                startCountdown();
            }

        } catch (error) {
            toast.error('Error sending verification code');
        }
        toast.success('Confirmation code sent');
    }

    const onVerify = async (code: string) => {
        stopCountdown();
        const result = await verifyConfirmationCode({
            confirmationCode: code,
            confirmationCode2: "",
            lenderUniqueId: "",
            password: "",
            provider: "",
            url: "",
            username: user.emailAddress,
        }).unwrap();
        if (result.confirmed) {
            toast.success('Edit access enabled. Your Microsoft associated email has been updated and added to your profile');
            props.onOpenChange(false);
        } else {
            setError('verificationCode', {
                type: 'manual',
                message: 'Invalid verification code',
            });
        }
    };

    const onCodeChange = (value: string) => {
        setValue('verificationCode', value, { shouldValidate: true });
        // if length of code is 6, verify
        if (value.length === 6) {
            onVerify(value);
        }
    };

    const values = watch(['emailAddress', 'verificationCode']);
    return {
        onChangeEmail,
        register,
        handleSubmit,
        onSubmit,
        onResendConfirmationCode,
        onVerify,
        onCodeChange,
        values: {
            emailAddress: values[0],
            verificationCode: values[1],
        },
        isLoading: isSubmitting || isReSendingConfirmationCode || isVerifyConfirmationCodeLoading || props.loading,
        errors,
        activeView,
        verificationCodeCountDown,
    } as const;
};