import FolderIcon from '@mui/icons-material/FactCheck';
import Money from '@mui/icons-material/Money';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

export const LOAN_TABS = {
    DETAILS: 'DETAILS',
    EMAIL_HISTORY: 'EMAIL_HISTORY',
    PACKAGE: 'PACKAGE',
}

export type ILoanTabs = keyof typeof LOAN_TABS;

type LoanTabItem = {
    label: string,
    value: ILoanTabs,
    icon: JSX.Element
}

export const TabsList: LoanTabItem[] = [
    {
        label: 'Overview',
        value: LOAN_TABS.DETAILS as ILoanTabs,
        icon: <Money />
    },
    {
        label: 'Package',
        value: LOAN_TABS.PACKAGE as ILoanTabs,
        icon: <FolderIcon />
    }
];

export const LoanTabsNav = (props) => {
    const { valueBuffer, value, onTabChange, title, currentTab } = props;
    return <Box
        sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: 'neutral.400',
            pt: {
                xs: 1,
                lg: 2
            }
        }}>
        <Card>
            <CardContent sx={{
                pt: 0,
                pb: 0,
                mb: 0,
                px: {
                    xs: 0,
                    lg: 1
                },
                "&:last-child": {
                    paddingBottom: 0,
                    paddingTop: 1
                }
            }}>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress sx={{
                        mx: 1,

                        '& .MuiLinearProgress-dashed': {
                            animation: 'none',
                            backgroundImage: 'none',
                            backgroundColor: 'grey.300',
                        },
                        '& .MuiLinearProgress-colorSuccess': {
                            animation: 'none',
                            backgroundImage: 'none',
                            backgroundColor: 'warning.main',
                        }
                    }}
                        color='success'
                        variant="buffer"
                        value={value} valueBuffer={valueBuffer} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='subtitle2' sx={{
                        mx: 1,
                        display: {
                            xs: 'none',
                            sm: 'block',
                        }
                    }}>
                        {title}
                    </Typography>
                    <Tabs
                        sx={{
                            mt: 1,
                            flex: 1,
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.2rem'
                            },
                            '& .MuiButtonBase-root': {
                                py: 0,
                                minHeight: 48
                            }
                        }}
                        indicatorColor="primary"
                        onChange={onTabChange}
                        scrollButtons="auto"
                        textColor="primary"
                        value={currentTab}
                        variant="scrollable"
                    >
                        {TabsList.map((tab) => (
                            <Tab
                                key={tab.value}
                                icon={tab.icon}
                                label={tab.label}
                                value={tab.value}
                                id={`tab-${tab.value}` /* for selenium automation */}
                                sx={{
                                    marginLeft: {
                                        xs: '12px !important',
                                        sm: '24px !important'
                                    },
                                    fontSize: 12
                                }}

                            />
                        ))}
                    </Tabs>
                </Box>
            </CardContent>
        </Card>
    </Box>
}