import { Role } from "src/backend";
import { useGetLoggedInUserQuery } from "src/services/userApi";
import { Loan } from "src/types/loan";
import { isRoleABorrower } from "src/utils/user/is-role-a-borrower";

interface UserProps {
    loan?: Loan;
}

export const useUser = (props: UserProps = {}) => {
    const { data: loggedInUser } = useGetLoggedInUserQuery();
    const loanRole = props.loan?.loanRoles.find((role) => role.user.id === loggedInUser.user.id);

    let userRole: Role = null;

    if (typeof loanRole !== 'undefined') {
        userRole = loanRole.role;
    } else if (loggedInUser?.user) {
        userRole = loggedInUser.user.roleDefault;
    }

    return {
        info: loggedInUser?.info,
        user: loggedInUser?.user,
        company: loggedInUser?.employer,
        loanRole,
        lenders: loggedInUser?.lenders,
        viewType: loggedInUser?.viewType,
        features: loggedInUser?.user?.features ?? [],
        userRole,
        onboardingNeeded: loggedInUser?.needOnboard,
        isBorrower: isRoleABorrower(userRole),
        isLender: !isRoleABorrower(userRole),
        isUnderwriter: userRole === 'UNDERWRITER_LENDER',
    } as const;
};